import {Box, Typography} from "@mui/material";
import React from "react";
import {Trans, useTranslation} from "react-i18next";

const InfoEvento = ({data, event_id}) => {
    const {t, i18n} = useTranslation()
    return (<>
            {data.invito === "Presenza" && <Box sx={{textAlign: 'center', pt: 4}}>
                <Typography variant={'h6'} color={event_id === "nj15" ? '#00bb7e' : '#0095c9'}>
                    <Trans i18nKey={'date_' + event_id} components={{1: <sup/>}} /> {t('time_' + event_id)}
                    <br/>
                    {t('location_' + event_id)}
                    <br/>
                    {t('address_' + event_id)}
                    <br/>
                    {t('city_' + event_id)}
                </Typography>
            </Box>}
        </>)
}

export default InfoEvento;

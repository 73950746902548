import React, {useEffect, useState, useTransition} from "react";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import {atlantiaTheme} from "../theme/atlantiaTheme";
import {useParams} from "react-router-dom";
import {API_URL} from "../config";
import axios from "axios";
import {useTranslation} from "react-i18next";
import FormPartecipazione from "../components/FormPartecipazione";
import {t} from "i18next";
import DatiUtente from "../components/DatiUtente";
import InfoEvento from "../components/InfoEvento";
import Contatti from "../components/Contatti";
import {LanguageSwitcher} from "../components/LanguageSwitcher";

const mockData = {
    nome: 'Plesh',
    cognome: 'Tech',
    email: 'hello@plesh.co',
    evento: 'Entrambi',
    invito: 'Presenza',
    lingua: 'Ita'
}

export const Home = () => {
    const {t, i18n} = useTranslation()
    const {hash} = useParams()
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState("")
    const [formError, setFormError] = useState("")

    useEffect(() => {
        setLoading(true)
        getDati().then()
    }, [])

    const getDati = async () => {
        await axios.get(`${API_URL}/get-data-user/${hash}`)
            .then(r => {
                const userData = r.data;
                userData.lingua = (userData.lingua === 'Ita' ? 'it' : 'en')
                setData(userData)
                i18n.changeLanguage(userData.lingua)
                setLoading(false)
                setSending("")
            })
            .catch(() => {
                setLoading(false)
                setError(true)
            })
    }

    const sendDati = async (event_id, partecipazione) => {
        setSending(event_id)
        setFormError("")
        await axios.post(`${API_URL}/register/${hash}`, {
            ["partecipazione" + (event_id === "nj15" ? "15" : "16")]: partecipazione
        })
            .then(r => {
                getDati().then()
                setSuccess(true)
            })
            .catch(() => {
                setFormError(event_id)
                setSending("")
            })
    }

    return (<Box sx={{position: 'relative'}}>
        <Box sx={{width: 'min(100%, 25rem)', margin: 'auto', p: 1}}>
            <img style={{width: '100%'}}
                 src={(data && data.evento === "nr16") ? require('../assets/images/newrhythm.jpg') : require('../assets/images/newjourney.png')}/>
        </Box>
        <Container>
            <Stack maxWidth={"sm"} sx={{margin: "auto"}} direction={"row"} justifyContent={"end"}>
                <LanguageSwitcher />
            </Stack>
            {loading ? <Box sx={{textAlign: 'center'}}>
                <CircularProgress/>
            </Box> : (error ? <Box>
                <Alert severity={'error'}>
                    {t('link_error')}
                </Alert>
                <Contatti event_id={"nj15"}/>
            </Box> : <Box>
                <Box sx={{py: 2}}>
                    <Typography color={'#00bb7e'} variant={'h4'} textAlign={'center'} fontWeight={'bold'}>
                        {t('title')}
                    </Typography>
                </Box>
                {["nj15", "nr16"].map((event_id) =>
                    (data.evento === event_id || data.evento === "Entrambi") ? <Box key={event_id}>

                        {event_id === "nr16" && data.evento === "Entrambi" && <Divider sx={{mt: 4}}/>}

                        {data.evento === "Entrambi" && event_id === "nr16" &&
                            <Box sx={{width: 'min(100%, 25rem)', margin: 'auto', p: 1}}>
                                <img style={{width: '100%'}} src={require('../assets/images/newrhythm.jpg')}/>
                            </Box>}

                        <InfoEvento data={data} event_id={event_id}/>

                        <Container maxWidth={'sm'} sx={{pt: 2}}>
                            <DatiUtente data={data}/>

                            {sending === event_id ? <Box sx={{textAlign: 'center', pt: 2}}>
                                <CircularProgress/>
                            </Box> : <FormPartecipazione
                                sending={sending}
                                partecipazione={event_id === "nj15" ? data.partecipazione15 : data.partecipazione16}
                                event_id={event_id} data={data} sendDati={sendDati}/>}

                            {formError === event_id && <Alert sx={{mt: 2}} severity={'error'}>
                                {t('form_error')}
                            </Alert>}
                            {event_id === "nj15" &&
                                <Typography textAlign={'center'} sx={{pt: 3}}>
                                    <a href={t('privacy_url')} target={'_blank'}>{t('informativa_privacy')}</a>
                                </Typography>
                            }

                            <Contatti event_id={event_id}/>
                        </Container>
                    </Box> : <Box/>
                )}
            </Box>)}
        </Container>
    </Box>)
}

import React from "react";
import {Box} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useTranslation} from "react-i18next";

export const LanguageSwitcher = () => {
    const {t,i18n} = useTranslation()
    return (
        <FormControl sx={{width:'4rem'}} variant={'standard'}>
            <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={i18n.language}
                label={t('language')}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
            >
                <MenuItem value={'it'}>It</MenuItem>
                <MenuItem value={'en'}>En</MenuItem>
            </Select>
        </FormControl>
    )
}

import {createTheme, responsiveFontSizes} from "@mui/material";

export const atlantiaTheme = responsiveFontSizes(createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#40b285',
            contrastText: 'white'
        },
        secondary: {
            main: '#0095c9',
            contrastText: 'white'
        }
    }
}))

import {Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const Contatti = ({event_id}) => {

    const {t, i18n} = useTranslation()

    return (
        <Typography textAlign={'center'} sx={{py: 5}}>
            {t('problems')}
            <br/>
            <a href={'mailto:atlantia.' + (event_id === "nj15" ? 'newjourney' : 'newrhythm') + '@ninetynine.biz'}
               target={'_blank'}>atlantia.{(event_id === "nj15" ? 'newjourney' : 'newrhythm')}@ninetynine.biz</a>
        </Typography>
    )
}

export default Contatti;

import {Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const DatiUtente = ({data}) => {
    const {t, i18n} = useTranslation()
    return (
        <>
            <Typography>
                <b>{t('name')}:</b> {data.nome}
            </Typography>
            <Typography>
                <b>{t('surname')}:</b> {data.cognome}
            </Typography>
            <Typography>
                <b>{t('email')}:</b> {data.email}
            </Typography>
        </>
    )
}

export default DatiUtente;

import './App.css';
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {Home} from "./pages/Home";
import {Live} from "./pages/Live";
import {Helmet} from "react-helmet";
import {Press} from "./pages/Press";
import {useEffect} from "react";

function App() {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{window.location.hostname === 'newrhythm.it' ? 'New Rhythm' : 'New Journey'}</title>
            </Helmet>
            <Routes>
                <Route path={'/'} element={<Navigate to={'/live'}/>}/>
                <Route path={'/:hash'} element={<Home/>}/>
                <Route path={'/live'} exact element={<Live/>}/>
                <Route path={'/press'} exact element={<Press/>}/>
            </Routes>
        </div>
    );
}

export default App;

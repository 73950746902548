import React from "react";
import {Box, Container, Typography} from "@mui/material";

const event_id = window.location.hostname === 'newrhythm.it' ? 'nr15' : 'nj15'
export const Live = () => {
    return (
        <Box sx={{height: {xs:'max(100vh, 38rem)' , md:'max(100vh, 48rem)'}, position:'relative'}}>
            <Box sx={{width:'min(100%, 25rem)', position:'absolute', top:0, right:0, rotate:'0', transform: 'scale(-1, 1)', display:{xs:'none', md:'block'}}}>
                <img src={require('../assets/images/line.png')} style={{width: '100%'}}/>
            </Box>
            <Box sx={{width:'min(90%, 30rem)', position:'absolute', bottom:0, left:30}}>
                <img src={require('../assets/images/line.png')} style={{width: '100%'}}/>
            </Box>
            <Container maxWidth={'lg'} sx={{height:'100%', display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                <Box sx={{width: '100%', textAlign:'center'}}>
                    {/*<Box sx={{width: '100%'}}>
                        <img style={{width: '100%'}} src={require('../assets/images/' + event_id + '.jpg')}/>
                    </Box>*/}
                    <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                        <iframe
                            src={event_id === 'nj15' ? "https://players.plesh.co/atlantia-2023" : "https://players.plesh.co/atlantia-2023-16marzo"}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                        ></iframe>
                    </div>
                    <Box sx={{width:'100%', textAlign:'center', pt:1}}>
                        <Typography textAlign={'right'}>
                            Se hai problemi con il player <a href={'https://vimeo.com/event/3170287/276d77a94f'} target={'_blank'}>clicca qui</a> oppure scrivi a <a href={'mailto:atlantia.' + (event_id === "nj15" ? 'newjourney' : 'newrhythm') + '@ninetynine.biz'}
                                                                                                                            target={'_blank'}>atlantia.{(event_id === "nj15" ? 'newjourney' : 'newrhythm')}@ninetynine.biz</a>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

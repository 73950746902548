import React, {useEffect, useState, useTransition} from "react";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider, List,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import {atlantiaTheme} from "../theme/atlantiaTheme";
import {useParams} from "react-router-dom";
import {API_URL} from "../config";
import axios from "axios";
import {useTranslation} from "react-i18next";
import FormPartecipazione from "../components/FormPartecipazione";
import i18next, {t} from "i18next";
import DatiUtente from "../components/DatiUtente";
import InfoEvento from "../components/InfoEvento";
import Contatti from "../components/Contatti";
import {LanguageSwitcher} from "../components/LanguageSwitcher";

const bucket_url = 'https://storage.googleapis.com/atlantia-public-imgs/'

export const Press = () => {
    const {t, i18n} = useTranslation()

    const files = [
        {label: t("download-press-kit"), name: "press-kit/MundysMediaKit.zip"},
        {label: t("download-pictures"), name: 'photos/Pictures.zip'},
        //{label: t("download-pictures"), name: 'photos/Pictures.zip'},
        /*{label: "Logo Mundys PNG", name: "MUNDYS_LOGO_PAYOFF_CMYK_POS.png"},
        {label: "Logo Mundys JPG", name: "MUNDYS_LOGO_PAYOFF_CMYK_POS.jpg"},
        {label: "Animation Logo Mundys", name: "mundys_logo-animation_GLOBAL_WhiteBG.mp4"},
        {label: "Animation Logo Mundys con ASSET", name: "mundys_logo-animation_GLOBAL_WhithASSETS_WhiteBG.mp4"},*/
    ]


    const {hash} = useParams()
    const evento = new Date().getDate() <= 15 ? 'nj15' : 'nr16'

    return (<Box sx={{position: 'relative'}}>
        <Box sx={{width: 'min(100%, 25rem)', margin: 'auto', p: 1}}>
            <img style={{width: '100%'}}
                 src={evento === "nr16" ? require('../assets/images/newrhythm.jpg') : require('../assets/images/newjourney.png')}/>
        </Box>
        <Container>
            <Stack maxWidth={"sm"} sx={{margin: "auto"}} direction={"row"} justifyContent={"end"}>
                <LanguageSwitcher />
            </Stack>
            <Stack alignItems={"center"}>
                <Box sx={{py: 2}}>
                    <Typography color={'#00bb7e'} variant={'h4'} textAlign={'center'} fontWeight={'bold'}>
                        {t('press-kit')}
                    </Typography>
                </Box>
                <Stack maxWidth={'400px'} spacing={1}>
                    {files.map((file) => (
                        <Button variant={'outlined'}
                                target={"_blank"}
                                download
                                href={`${bucket_url}${file.name}`}
                        >
                            {file.label}
                        </Button>
                    ))}
                </Stack>
            </Stack>
        </Container>
    </Box>)
}

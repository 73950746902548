import {Box, Button, CircularProgress, Container, Stack, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const FormPartecipazione = ({partecipazione, event_id, sendDati, sending}) => {
    const {t, i18n} = useTranslation()
    return (<>
            {partecipazione ? <Typography sx={{textAlign: 'center', pt: 2}} fontWeight={'bold'} variant={'h6'}>
                {partecipazione === "si" ? t('will-attend') : t('wont-attend')}
            </Typography> : <Stack direction={{xs: 'column', md: 'row'}} justifyContent={'space-between'}
                                   sx={{pt: 2}}
                                   spacing={4}>
                <Button sx={{width: {xs: '100%'}}}
                        disabled={sending !== ""}
                        color={event_id === "nj15" ? 'primary' : 'secondary'}
                        onClick={() => sendDati(event_id, "si")}
                        variant={'contained'}>{t('attend')}</Button>
                <Button sx={{width: {xs: '100%'}}}
                        disabled={sending !== ""}
                        color={event_id === "nj15" ? 'primary' : 'secondary'}
                        onClick={() => sendDati(event_id, "no")}
                        variant={'outlined'}>{t('no-attend')}</Button>
            </Stack>}
        </>)
}

export default FormPartecipazione;
